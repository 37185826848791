<script>
import gsap from 'gsap';
import BaseRecommendations from '~/components/base/BaseRecommendations.vue';
import ProductResources from '~/components/products/ProductResources.vue';
import ProductCrops from '~/json/products.crops.json';
import cropResources from '../../../json/languages/en/en.crops.resources.json';
import { useI18n } from "vue-i18n";

export default {
  components: {
    BaseRecommendations,
    ProductResources
  },
  data() {
    return {
      selectedNav: 0,
      productFamilies: null,
      productCrops: null,
      products: null,
      resources: null,
      terms: null,
    };
  },
  mounted() {
  },
  methods: {
    handleSelection(index) {
      if (this.selectedNav != index) {
        const id = '#nav' + index;
        const prevId = '#nav' + this.selectedNav;
        gsap.to(id, {
          textDecoration: 'underline',
        });
        gsap.to(prevId, {
          textDecoration: 'none',
        });
        this.selectedNav = index;
      }
    },
    checkImageExists(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.addEventListener('load', () => resolve(true));
        img.addEventListener('error', () => resolve(false));
        img.src = url;
      });
    },
  },
  computed: {
    crops() {
      return this.$tm('crops');
    },
    crop() {
      const route = useRoute();
      return this.crops.find((item) => (this.$rt(item.cropURL) === route.params.id));
    },
    cropHead() {
      return this.$rt(this.crop.cropHead);
    },
    cropText() {
      return this.$rt(this.crop.cropText);
    },
    cropsTerm() {
      var terms = this.$tm('extraTerms');
      var cropTerm = terms.find((element) => this.$rt(element.mlVar) === 'crops').mltText;
      return cropTerm;
    },
    cropBanner() {
      if (this.crop.cropURL) {
        let link = {
          resourceUri: '/images/crops/' + this.$rt(this.crop.cropURL) + '-hero-' + this.$rt(this.crop.cropHero) + '.webp',
          bannerLink: this.cropsTerm,
          bannerLinkPath: '/crops',
          title: this.crop.crop
        };
        return link;
      }
      else { return {}; }
    },
    cropProducts() {
      let prodFamilies = this.$tm('productFamilies');
      let prodSpecs = this.$tm('products');
      let terms = this.$tm('extraTerms');
      const crops = ProductCrops.productCrops.filter((element) => element.cropID_fk === this.crop.cropID_fk);
      var currentCropProducts = crops.map((element, index) => {
        let filterdCrop = prodSpecs.find(({ pID }) => pID === element.pID_fk);
        let prodFam = null;
        if (filterdCrop) {
          prodFam = prodFamilies.find(({ pfID }) => pfID === filterdCrop.pfID_fk);//figure out why when this is in english it uses pfID vs pfID_fk
          filterdCrop.productFam = prodFam;
          filterdCrop.index = this.crop.cropURL + index;
        }
        return filterdCrop;
      });
      currentCropProducts = currentCropProducts.filter(n => n != undefined);

      const productTerm = terms.find((element) => {
        if (element.mlVar != null) {
          return this.$rt(element.mlVar) === 'products'
        }
      });
      const formatCropProducts = {
        title: productTerm.mltText,
        items: currentCropProducts
      };
      return formatCropProducts;
    },
    cropResources() {
      let cropRes = this.$tm('cropResources');
      var curLocale = useI18n().locale.value;
      var resources = [];
      //need to get english resources first.
      var enResources = cropResources.cropResources.filter(
        (element) => parseInt(element.cspID_fk) === parseInt(this.crop.cropID_fk)//only ones that are not en
      )
      if (curLocale !== 'en') {
        resources = cropRes.filter(
          (element) => (parseInt(element.cspID_fk) === parseInt(this.crop.cropID_fk)) && (element.filename !== null || element.videoURL !== null)
        );
      }
      if (curLocale === 'en')
        resources = enResources;
      else {
        enResources.forEach((en) => {
          var found = resources.find((re) => re.libID_fk === en.libID_fk);
          if (!found) {
            resources.push(en);
          }
        })
      }

      return resources;
    },
  },
};
</script>
<template>
  <div>

    <Head v-if="crop !== null">
      <Title>{{ crop.cropPageTitle === null ? $rt(crop.crop) : $rt(crop.cropPageTitle) }}</Title>
      <Meta name="description" :content="crop.cropMetaDesc === null ? $rt(crop.crop) : $rt(crop.cropMetaDesc)" />
    </Head>
  </div>
  <BaseBanner :items="cropBanner" />
  <div v-show="crop" class="crops-container">
    <div class="crop-detail-container">
      <section class="crops-section">
        <div class="story-center">
          <div class="crop-head" v-html="cropHead"></div>
          <div class="crops-detail-text" v-html="cropText"></div>
        </div>
      </section>
    </div>
    <ProductResources :items="cropResources" v-show="cropResources" />
    <BaseRecommendations :items="cropProducts" v-show="cropProducts" />

  </div>
</template>
<style>
.crop-head p,
.sg-head {
  font-size: 1.6rem;
  max-width: 700px;
  line-height: 1.2em;
  margin-bottom: 25px;
  margin-left: 20px;
}

.crop-detail-container {
  width: 100%;
  background-color: #F7F6F1;
  align-self: center;
}

.crops-detail-text p {
  margin-bottom: 10px;
  margin-left: 20px;
}


.crops-section {
  width: 100%;
  margin: 0 auto 0;
}

.crops-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}
</style>
